
import { Component } from '@/lib/decorator';
import Form from '@/layouts/Form';
import TitleReturn from '../../components/TitleReturn.vue';
import FormBlock from './profile/_form.vue';

import EmployeesModule from '@/store/employees';

@Component({
  components: {
    TitleReturn,
    FormBlock,
  },
})
export default class EmployeesEntityAdd extends Form {
  get titlePage(): string {
    return EmployeesModule.pageSettings.titleAdd;
  }

  returnBefore(): void {
    this.$router.push({ name: 'employees' });
  }
}
